<template>
  <v-container fluid style="height: 100%; background-color: white">
    <headerToolbar color="white" divider @invitationSended="getOrganisations">
      <template v-slot:toolbarTitle>
        <b class="ml-3">{{ $t("navigationMenu.organisations") }}</b>
      </template>
    </headerToolbar>

    <organisation-members v-if="organisations.length > 0" />

  </v-container>
</template>

<script>
import headerToolbar from "../shared/headerToolbar";
import invitations from "./invitations";
import organisationMembers from "./organisationMembers";
import { mapActions, mapGetters } from "vuex";

export default {
  name: 'organisations',
  components: {
    headerToolbar,
    organisationMembers
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['organisations'])
  },
  methods: {
    ...mapActions(['getOrganisations'])
  },
  mounted() {
    this.getOrganisations();
  },
   created() {
    document.title = this.$t("navigationMenu.organisations")
    this.$amplitude.getInstance().logEvent("Page: Organizations");
  },
}
</script>


