<template>
  <v-container fluid class="pt-0">
    <v-row wrap no-gutters align="center" class="pt-4 pb-8">
          <v-col
            shrink
            style="max-width: 100px"
            class="align-center d-flex justify-center"

          >
            <v-hover v-slot="{ hover }" v-if="selectedOrganisationId">
              <div @click="showUpdateOrgProfilPic = true">
                <userAvatar
                  :size="64"
                  :last_name="selectedOrganisation.name"
                  rounded
                  :avatar_url="selectedOrganisation.picture_url"
                >
                  <template slot="imgSlot">
                    <v-expand-x-transition>
                      <div
                        v-if="hover"
                        class="d-flex transition-fast-in-fast-out button_blue display-3 white--text"
                        style="cursor: pointer; height: 100%; width: 100%"
                      >
                        <v-icon color="white" dark class="mx-auto">
                          mdi-image-edit-outline
                        </v-icon>
                      </div>
                    </v-expand-x-transition>
                  </template>
                </userAvatar>
              </div>
            </v-hover>
            <v-skeleton-loader
              class="orgAvatarSkeleton"
              v-else
              type="avatar"
            ></v-skeleton-loader>
          </v-col>
          <v-col shrink style="max-width: 300px" class="justify-start">
            <v-select
              v-if="selectedOrganisationId"
              :value="selectedOrganisationId"
              dense
              :items="organisations"
              item-text="name"
              item-value="id"
              persistent-hint
              :menu-props="{ bottom: true, offsetY: true }"
              solo
              flat
              background-color="white"
              :hint="`${selectedOrganisation.nb_members} ${
                selectedOrganisation.licences_count
                  ? `${$t('misc.on')} ${selectedOrganisation.licences_count}`
                  : ''
              } members`"
              ref="orgaSelect"
            >
              <template v-slot:prepend-item>
                <v-subheader class="pl-4">
                  {{ $t("organisations.selectOrganisation") }} :
                </v-subheader>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item
                  two-line
                  ripple
                  @click="selectOrganisationId(item.id)"
                >
                  <v-list-item-action class="mr-4">
                    <userAvatar
                      :size="32"
                      :last_name="item.name"
                      rounded
                      :avatar_url="item.picture_url"
                    />
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.nb_members }} members
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
            <v-skeleton-loader v-else type="text@2"></v-skeleton-loader>
          </v-col>

          <v-spacer></v-spacer>
          <v-btn
            v-if="selectedOrganisationId"
            class="mr-8"
            dark
            depressed
            color="button_blue"
            @click="inviteDialog = true"
            ><v-icon left small>mdi-account-multiple-plus</v-icon
            >{{ $t("organisations.inviteMembers") }}</v-btn
          >
          <v-skeleton-loader
            class="mr-8"
            v-else
            type="button"
          ></v-skeleton-loader>

    </v-row>

    <v-divider></v-divider>

    <v-container fluid>
      <v-tabs
        dense
        color="button_blue"
        background-color="transparent"
        v-model="selectedTab"
      >
        <v-tab value="organisationMembers"
          ><v-icon size="18" left> mdi-account-group </v-icon
          >{{ $t("organisations.membersHeaders.members") }}</v-tab
        >
      </v-tabs>

      <v-tabs-items v-model="selectedTab">
        <v-tab-item key="organisationMembers">
          <v-container
            fluid
            class="pb-0 mt-4 px-0"
            style="height: calc(100vh - 278px); overflow-y: auto"
          >
            <v-skeleton-loader
              v-if="!selectedOrganisation"
              type="table: heading@5, table-thead, table-row-divider@5, table-tfoot"
            ></v-skeleton-loader>

            <paginatedTable
              v-else
              :headers="membersHeaders"
              :graphqlQuery="getGraphqlMemberQuery()"
              :refresh="refreshMembersIndex"
              resourceName="organisation_users"
              :filter="organisationMembersFilter"
              :customSlots="['user.email', 'user.first_name', 'user.actions']"
            >
              <template v-slot:[`user.email`]="{ slotScope: { item } }">
                <span class="caption">{{ item.user.email }}</span>
              </template>

              <template v-slot:[`user.first_name`]="{ slotScope: { item } }">
                <v-layout class="align-center">
                  <userAvatar
                    :size="16"
                    :show_name_in_tooltip="false"
                    :first_name="item.user.first_name"
                    :last_name="item.user.last_name"
                    :avatar_url="item.user.avatar_url"
                    :email="item.user.email"
                  />
                  <span style="padding-left: 8px">
                    {{
                      item.user.first_name && item.user.last_name
                        ? item.user.first_name + " " + item.user.last_name
                        : item.user.email
                    }}
                  </span>
                  <span v-if="item.user.id == selectedOrganisationOwnerId">
                    <v-chip
                      class="ml-4"
                      color="timeline_lighter_blue"
                      text-color="white"
                      small
                      label
                    >
                      {{ $t("misc.organisationOwner") }}
                    </v-chip>
                  </span>
                </v-layout>
              </template>

              <template v-slot:[`user.actions`]="{ slotScope: { item } }">
                <v-tooltip left color="error">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="deleteOrganisationUser(item.id)"
                      v-bind="attrs"
                      v-on="on"
                      v-if="isOwner && item.user.id != currentUser.id"
                    >
                      <v-icon color="error" small>mdi-account-remove</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("organisations.excludeMemberAlertTitle") }}</span>
                </v-tooltip>
              </template>
            </paginatedTable>
          </v-container>
        </v-tab-item>

      </v-tabs-items>
    </v-container>
    <!-- <v-row v-if="organisations.length === 0" class="text-center">
      <span class="text--primary caption">
        {{ $t("organisations.organisationsEmpty") }}
      </span>
      <a href="/set_organisation" class="caption">
        {{ $t("organisations.organisationsEmptyCreateLink") }}
      </a>
    </v-row> -->

    <!-- Email Invitations Dialog -->
    <v-dialog v-model="inviteDialog" persistent max-width="560">
      <v-card>
        <v-card-title class="title font-weight-regular justify-space-between">
          <span>{{ $t("organisations.inviteMembers") }}</span>
          <v-btn
            :disabled="
              emailsInvitationLoading == 1 || emailsInvitationLoading == 2
            "
            icon
            @click="closeInviteDialog()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="text-center">
          <v-window v-model="emailsInvitationLoading">
            <!-- Emails to invite -->
            <v-window-item :value="0">

              <v-combobox
                ref="emailTextInput"
                v-model="emailsToInvite"
                :label="placeholderInvitation"
                multiple
                deletable-chips
                :delimiters="[',', ' ']"
                small-chips
                validate-on-blur
                filled
                chip-color="button_blue"
                solo
                flat
                dense
                prepend-icon="mdi-account-multiple-plus"
                @change="parseMails"
                :rules="[
                  (e) =>
                    !emailsToInvite ||
                    validEmails ||
                    $t('organisations.invalidEmailMsg'),
                ]"
              ></v-combobox>

              <span>{{ $t("organisations.youCanInviteMultiplePeople") }}</span>
            </v-window-item>

            <!-- Loading animation-->
            <v-window-item :value="1">
              <ripple-loader color="blue" />
              <h4>{{ $t("organisations.sendInvitations") }}</h4>
              <span>{{ $t("misc.pleaseWait") }}</span>
            </v-window-item>

            <!-- Success ! -->
            <v-window-item :value="2">
              <v-icon size="72" color="success">mdi-email-check</v-icon>
              <h4>{{ $t("organisations.invitationsSended") }}</h4>
            </v-window-item>

            <!-- Error! -->
            <v-window-item :value="3">
              <v-icon size="64" color="error">mdi-email-alert</v-icon>
              <h4>{{ $t("organisations.errorWhileSendingInvitation") }}</h4>
              <span>{{ $t("organisations.someInvitationError") }}</span>
            </v-window-item>
          </v-window>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions v-show="emailsInvitationLoading != 2">
          <v-btn
            v-if="emailsInvitationLoading == 0"
            small
            color="almost_black"
            text
            @click="closeInviteDialog()"
          >
            {{ $t("misc.cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :loading="emailsInvitationLoading > 0"
            v-if="emailsInvitationLoading < 2"
            depressed
            small
            color="button_blue"
            class="white--text"
            @click="sendInvitations"
          >
            <v-icon small left>mdi-send</v-icon>
            {{ $t("organisations.sendInvitations") }}
          </v-btn>

          <v-btn
            v-if="emailsInvitationLoading == 3"
            depressed
            small
            dark
            text
            color="almost_black"
            @click="closeInviteDialog()"
          >
            <v-icon small left>mdi-close</v-icon>
            {{ $t("misc.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Update organisation profil picture -->
    <v-dialog v-model="showUpdateOrgProfilPic" persistent max-width="800">
      <v-card>
        <v-card-title class="title font-weight-regular justify-space-between">
          <span>{{ $t("organisations.updateOrganisationPicture") }}</span>
          <v-btn icon @click="closeOrgProfilPicDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text-center py-4 px-16">
          <v-row no-gutters class="pb-5" align="center">
            <userAvatar
              :size="72"
              :last_name="organisation.name"
              rounded
              :avatar_url="organisation.picture_url"
            />

            <v-col class="ml-5 text-left">
              <uploader
                v-on:upload="setOrgaPictureBlob($event.file, $event.blob)"
              >
                <template v-slot:btn>
                  <v-btn
                    class="mr-3 white--text"
                    depressed
                    small
                    color="button_blue"
                  >
                    {{ $t("userProfile.change") }}
                  </v-btn>
                </template>
              </uploader>
              <v-btn
                outlined
                depressed
                small
                @click="removeOrganisationProfilPic()"
                :disabled="noOrganisationProfilPic"
              >
                {{ $t("userProfile.remove") }}
              </v-btn>
              <p class="mb-0">
                <span class="caption">{{
                  $t("userProfile.uploadRequierements")
                }}</span>
              </p>
            </v-col>
          </v-row>

          <v-icon color="button_blue" small left>mdi-alert-circle</v-icon
          ><span class="caption">{{
            $t("organisations.organisationPictureDesc")
          }}</span>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="almost_black"
            text
            @click="closeOrgProfilPicDialog()"
          >
            {{ $t("misc.back") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import paginatedTable from "@/components/shared/paginatedTable";
import userAvatar from "@/components/shared/userAvatar";
import PAGINATED_ORGANISATION_MEMBERS_INDEX from "@/graphql/organisations/paginated_members_index.gql";
import PAGINATED_ORGANISATION_INVITATIONS_INDEX from "@/graphql/organisation_invitations/paginated_index.gql";
import BULK_CREATE_ORGANISATION_USER from "@/graphql/organisation_users/bulk_create.gql";
import UPDATE_ORGANISATION from "@/graphql/organisations/update.gql";
import REMOVE_ORGANISATION_PICTURE from "@/graphql/organisations/remove_picture.gql";
import uploader from "@/components/shared/uploader";

export default {
  name: "organisationMembers",
  components: { paginatedTable, userAvatar, uploader },
  data() {
    return {
      showUpdateOrgProfilPic: false,
      inviteDialog: false,
      headers: ["user"],
      refreshInvitationIndex: 0,
      refreshMembersIndex: 0,
      emailsToInvite: [],
      emailsToInviteErrors: [],
      emailsToInviteError: "",
      emailsInvitationLoading: 0,
      email: "",
      loading: false,
      selectedTab: "organisationMembers",
      membersHeaders: [
        {
          text: this.$t("organisations.membersHeaders.members"),
          align: "start",
          sortable: false,
          value: "user.first_name",
        },
        {
          text: this.$t("organisations.membersHeaders.emails"),
          align: "start",
          sortable: true,
          value: "user.email",
        },
        {
          text: this.$t("organisations.membersHeaders.job"),
          align: "start",
          sortable: true,
          value: "user.job",
        },
        {
          text: this.$t("organisations.membersHeaders.groups"),
          align: "start",
          sortable: false,
          value: "user.groups",
        },
        {
          text: this.$t("organisations.membersHeaders.lastConnexion"),
          align: "start",
          sortable: false,
          value: "user.last_connexion",
        },
        {
          text: this.$t("organisations.membersHeaders.status"),
          align: "start",
          sortable: false,
          value: "user.status",
        },
        {
          align: "end",
          text: this.$t("misc.actions"),
          value: "user.actions",
          sortable: false,
        },
      ],
      pendingInvitationsHeaders: [
        {
          text: this.$t("organisations.membersHeaders.emails"),
          sortable: true,
          value: "invited_user_email",
        },
        {
          text: this.$t("organisations.invitedBy"),
          sortable: false,
          value: "inviting_user.last_name",
        },
        {
          align: "end",
          text: this.$t("misc.actions"),
          value: "inviting_user.actions",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "organisations",
      "ownedOrganisations",
      "selectedOrganisationId",
      "selectedOrganisation",
      "selectedOrganisationOwnerId",
    ]),
    isOwner() {
      if (!this.selectedOrganisationId) return false;
      if (!this.ownedOrganisations) return false;

      if (
        this.ownedOrganisations
          .map((e) => e.id)
          .includes(this.selectedOrganisationId)
      )
        return true;
      return false;
    },
    memberLabel() {
      return this.isOwner
        ? this.$t("organisations.ownerLabel")
        : this.$t("organisations.memberLabel");
    },
    organisationMembersFilter() {
      if (!this.selectedOrganisationId) return "";
      return `organisation_id == '${this.selectedOrganisationId}' && excluded == false`;
    },
    organisation() {
      if (!this.selectedOrganisationId) return "";
      return this.organisations.find(
        (e) => e.id === this.selectedOrganisationId
      );
    },
    organisationName() {
      if (!this.selectedOrganisationId) return "";
      return this.organisations.find(
        (e) => e.id === this.selectedOrganisationId
      ).name;
    },
    placeholderInvitation() {
      return `email@${
        this.organisationName
          ? this.organisationName.toLowerCase()
          : "missingOrgName"
      }.com`;
    },
    validEmails() {
      return (
        this.emailsToInvite
          .map((e) => !!e.trim().match(/^(.+)@(.+)$/))
          .filter((e) => !e).length === 0
      );
    },
    noOrganisationProfilPic() {
      return false;
    },
  },
  methods: {
    ...mapActions([
      "openDestroyDialog",
      "getOrganisations",
      "setSelectedOrganisationId",
    ]),
    deleteOrganisationUser(id) {
      this.openDestroyDialog({
        id: id,
        model: "organisation_user",
        onDestroy: this.onOrganisationUserDestroy,
        title: this.$t("organisations.excludeMemberAlertTitle"),
        message: this.$t("organisations.excludeMemberAlertMessage"),
      });
    },
    onOrganisationUserDestroy() {
      this.getOrganisations();
      this.refreshMembers();
    },
    refreshMembers() {
      this.refreshMembersIndex++;
    },
    getGraphqlMemberQuery() {
      return PAGINATED_ORGANISATION_MEMBERS_INDEX;
    },
    sendInvitations() {
      // Input box change event is trigger by the blur function so the parsemail event is working, then we wait the next tick to send invitations
      this.$refs.emailTextInput.blur();
      this.$nextTick(() => {
        if (this.loading === true) return;
        if (!this.validEmails)
          return this.sendError(this.$t("organisations.invalidEmailsMsg"));
        this.emailsInvitationLoading = 1;
        // this.emailsInvitationLoading = 0: not loading, 1: loading, 2: sucess, 3: error
        this.emailsToInviteErrors = [];
        this.$apollo
          .mutate({
            mutation: BULK_CREATE_ORGANISATION_USER,
            variables: {
              invited_user_emails: this.emailsToInvite,
              organisation_id: this.selectedOrganisationId,
            },
          })
          .then(({ data }) => {
            console.log("Data from invitation: ", data);
            this.refreshMembers();

            if (data.bulk_create_organisation_user.errors.length == 0) {
              this.emailsInvitationLoading = 2;
              setTimeout(() => this.closeInviteDialog(), 2000);
            } else {
              this.emailsInvitationLoading = 3;
              this.emailsToInviteErrors =
                data.bulk_create_organisation_invitation.errors;
            }
          })
          .catch((error) => {
            this.emailsInvitationLoading = 3;
            this.emailsToInviteError = error;
          });
      });
    },
    closeInviteDialog() {
      this.inviteDialog = false;
      this.emailsInvitationLoading = 0;
      this.emailsToInvite = [];
      this.emailsToInviteErrors = [];
      this.emailsToInviteError = "";
    },
    closeOrgProfilPicDialog() {
      this.showUpdateOrgProfilPic = false;
    },
    parseMails(v) {
      const reducer = (a, e) => [...a, ...e.split(/[, ]+/)];
      this.emailsToInvite = [...new Set(v.reduce(reducer, []))];
    },
    removeOrganisationProfilPic() {
      this.$apollo
        .mutate({
          mutation: REMOVE_ORGANISATION_PICTURE,
          variables: { id: this.selectedOrganisationId },
        })
        .then(({ data }) => {
          this.sendSuccess(this.$t("organisations.picRemoved"));
          this.getOrganisations();
        });
    },
    setOrgaPictureBlob(file, blob) {
      this.$apollo
        .mutate({
          mutation: UPDATE_ORGANISATION,
          variables: {
            id: this.selectedOrganisationId,
            picture_signed_blob_id: blob.signed_id,
          },
        })
        .then(({ data }) => {
          this.getOrganisations();
          this.showUpdateOrgProfilPic = false;
          this.sendSuccess(this.$t("organisations.picUpdated"));
        })
        .catch((error) => {
          this.sendError(error);
          console.log(error);
        });
    },
    selectOrganisationId(id) {
      this.setSelectedOrganisationId(id);
      this.$refs.orgaSelect.blur();
    },
  },
};
</script>

<style>
.orgAvatarSkeleton .v-skeleton-loader__avatar {
  border-radius: 4px !important;
}
</style>
